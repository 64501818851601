import React from "react";
import PageTitle from "../titles/page-title";

function Contacts() {
  return (
    <div className="content-container">
      <PageTitle title="Kontakty"></PageTitle>
      <h3>
        Pozemkové spoločenstvo<small>Jaroslav Orenič - predseda</small>
      </h3>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-4">
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-3 col-lg-2">
          <strong>IČO:</strong>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3">42381649</div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-3 col-lg-2">
          <strong>DIČ:</strong>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3">2024098560</div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-3 col-lg-2">
          <strong>IČ DPH:</strong>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3">SK2024098560</div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-3 col-lg-2">
          <strong>Číslo účtu:</strong>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3">
          SK22 0900 0000 0050 5918 0301
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-3 col-lg-2">
          <strong>Email:</strong>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3">
          jaroslav.orenic@gmail.com
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-3 col-lg-2">
          <strong>Telefón:</strong>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3">0905 296 856</div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-3 col-lg-2">
          <strong>Adresa:</strong>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3">
          <div>Združenie vlastníkov "p.s." Kečkovce</div>
          <div>Kečkovce 35/35 09011 Kečkovce</div>
        </div>
      </div>
      <br />
      <br />
      <h3>
        Dozorná rada<small>Mgr. Slavomíra Čabrejová - predseda</small>
      </h3>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-4">
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-3 col-lg-2">
          <strong>Email:</strong>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3">
          slavomira.cabrejova@gmail.com
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-3 col-lg-2">
          <strong>Telefón:</strong>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3">0905 873 432</div>
      </div>
      <br />
      <br />
      <h3>
        Odborný lesný hospodár<small>Ing. Stanislav Moľ</small>
      </h3>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-4">
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-3 col-lg-2">
          <strong>Email:</strong>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3">
          stanislav.mol@gmail.com
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-3 col-lg-2">
          <strong>Telefón:</strong>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3">0905 301 872</div>
      </div>
    </div>
  );
}

export default Contacts;
