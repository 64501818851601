import React from "react";

interface PageTitleProps {
  title: string;
}

function PageTitle({ title }: PageTitleProps) {
  return (
    <div className="page-title">
      <h1>{title}</h1>
      <hr />
    </div>
  );
}

export default PageTitle;
