import React from "react";
import PageTitle from "../titles/page-title";

function Home() {
  return (
    <div className="content-container">
      <PageTitle title="Domov"></PageTitle>
      <h2>Vitajte na stránke Združenia vlastníkov "p.s." Kečkovce.</h2>
      <p className="text">
        Združenie vlastníkov "p.s." Kečkovce je spoločenstvom vlastníkov
        spoločnej nehnuteľnosti v katastri obce Kečkovce. Pozemkové
        spoločenstvo, ako spoločenstvo s právnou subjektivitou, bolo zapísané
        dňa 22.02.2014 v registri pozemkových spoločenstiev, vedenom pozemkovým
        a lesným odborom Okresného úradu Svidník, vložka č. 0004/712. Členmi
        pozemkového spoločenstva sú všetci vlastníci podielov spoločnej
        nehnuteľnosti. Pozemkové spoločenstvo je podnikateľským subjektom podľa
        § 2 ods. (2) písm. c) Obchodného zákonníka. Pozemkové spoločenstvo je
        právnickou osobou, ktorá sa okrem všeobecne záväzných právnych predpisov
        riadi Zmluvou o pozemkovom spoločenstve vlastníkov spoločnej
        nehnuteľnosti v katastrálnom území Kečkovce.
      </p>
      <h3 className="section-title">
        Poslanie a cieľ pozemkového spoločenstva
      </h3>
      <p className="text">
        Poslaním pozemkového spoločenstva je zachovanie a zveľaďovanie lesného
        majetku pri rešpektovaní zásad trvalo udržateľného hospodárenia. Heslom
        lesníckej činnosti je dosahovanie maximálnych hospodárskych úžitkov v
        symbióze s umocňovaním plnenia verejno-prospešných funkcií lesa prírode
        blízkym obohospodarovaním.
      </p>
      <h3 className="section-title">História urbariátov na Slovensku</h3>
      <p className="text">
        Urbár sa dá voľne preložiť ako mestská pozemková kniha zaznamenávajúca
        poddanskú pôdu, ktorej vlastníkom je zemepán a držiteľom urbárny
        sedliak. Inými slovami je to teda súpis celkovej poddanskej pôdy a
        stanovenie povinnosti poddaných voči zemepánovi. V tomto kontexte ide o
        organizačnú štruktúru pozemkového spoluvlastníctva spravidla v rámci
        jednej dediny. Urbarialista-urbárnik je člen urbárskeho spoločenstva.
      </p>
      <p className="text">
        V záujme zlepšenia situácie a hospodárskeho vývoja v Uhorsku vydávala
        Mária Terézia a jej syn Jozef II množstvo opatrení a nariadení. Jedným z
        nich bol aj <strong>Tereziánsky urbár</strong>. Tereziánsky urbár bolo
        nariadenie, ktoré Mária Terézia vydala v roku 1767 a patrí k
        hospodárskym reformám. Okrem iného určil veľkosť poddanskej závislosti,
        na základe ktorej ustanovil povinnosti poddaných voči zemepánovi. Tak sa
        zo súkromno-právneho vzťahu medzi zemepánom a poddaným stal
        verejnoprávny vzťah, čím sa zlepšilo sociálne postavenie poddaných. Túto
        reformu možno považovať za najvýznamnejšiu. Urbáre boli písané v jazyku
        ľudu, teda v slovenských oblastiach po slovensky.
      </p>
      <p className="text">
        Táto reforma síce určila maximálne hranice dávok a povinností poddaným,
        no nijako nenarušila feudálne vlastníctvo pôdy a nezrušila ani
        nevoľníctvo. Aj Jozef II. Pokračoval v agrárnej reforme svojej matky a v
        roku 1785 zrušil v Uhorsku nevoľníctvo. V českých krajinách sa mu to
        podarilo už v roku 1781. Zaniklo týmto patentom večné priputanie roľníka
        k pôde, priznala sa osobná sloboda i právo voľne nakladať s hnuteľným
        majetkom. Rozhodnutím-patentom zo dňa 20.4.1785 vzniklo nové katastrálne
        rozdelenie chotárov a podarilo sa ho v Uhorsku dokončiť za 4 roky.
      </p>
      <p className="text">
        Základný nedostatok tereziánskeho urbára spočíva v tom, že kým maximálnu
        hranicu feudálnej renty určil jednotne, rozsah chotárskeho gruntu bol v
        každej stolici iný. Regulácia tiež nerozdelila ťažko obrábanú
        kopaničiarsku pôdu od úrodnej pôdy na dolniakoch. Takýmto zafixovaním
        výmery poddanskej pôdy roľníci už nemohli nadobudnuť ďalšiu pôdu a ani
        zemepán nemohol byť donútený k prídeleniu pôdy mnohopočetným rodinám. V
        rámci vypracovávania urbárov pre jednotlivé obce a panstvá sa vyznačili
        v Uhorsku sessie - usadlosti - dediny. Priemerná celá usadlosť bola
        stanovená na 1925 jutár ornej pôdy a k nej priľahlé pasienky. Základom
        naturálnych dávok poddaných bol deviatok z úrody a v platnosti zostával
        tiež cirkevný desiatok. Zemepán vyžadoval robotovať 52 dní do roka so
        záprahom alebo 104 dní pešo, za dom zaplatiť daň tzv. cenzus 1 zlatý
        ročne, porúbať 1 štvrtinovú siahu dreva a 3 dni robiť honca na
        poľovačkách. Podstatné bolo, že urbárska-poddanská pôda zachytená v
        urbári sa po zrušení poddanstva v roku 1848 stala podkladom pre jej
        prechod do súkromného vlastníctva poddaných, ktorí na nej pracovali.
      </p>
    </div>
  );
}

export default Home;
