import React from "react";
import { NavLink } from "react-router-dom";
import PageTitle from "../titles/page-title";

function Documents() {
  return (
    <div className="content-container">
      <PageTitle title="Dokumenty"></PageTitle>
      <nav>
        <NavLink to="zmluva">Zmluva o PS</NavLink>
        <NavLink to="uznesenia">Uznesenia</NavLink>
        <NavLink to="spravy">Správy</NavLink>
        <NavLink to="zavierky">Schválené účtovné závierky</NavLink>
      </nav>
    </div>
  );
}

export default Documents;
