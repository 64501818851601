import React from "react";
import { NavLink } from "react-router-dom";
import PageTitle from "../../titles/page-title";

function Reports() {
  return (
    <div className="content-container">
      <PageTitle title="Správy"></PageTitle>
      <nav>
        <NavLink to="cinnost">Správy o činnosti PS</NavLink>
        <NavLink to="projekty">Plány činností</NavLink>
        <NavLink to="dozorna-rada">Správy dozornej rady</NavLink>
        <NavLink to="financne-hospodarenie">Finančné hospodárenie</NavLink>
        <NavLink to="ine">Iné</NavLink>
      </nav>
    </div>
  );
}

export default Reports;
