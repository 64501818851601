import React from "react";
import { FaRegFilePdf } from "react-icons/fa";
import PageTitle from "../../../titles/page-title";
import YearTitle from "../../../titles/year-title";

function SupervisoryBoard() {
  return (
    <div className="content-container">
      <PageTitle title="Správy dozornej rady"></PageTitle>
      <YearTitle year={2024}></YearTitle>
      <div>
        <a
          href={
            process.env.PUBLIC_URL +
            "/pdf/reports/supervisory/Sprava_DR_Keckovce_2023.pdf"
          }
          target="_blank"
          rel="noreferrer"
        >
          <FaRegFilePdf /> Správa dozornej rady za rok 2023
        </a>
      </div>
      <br />
      <YearTitle year={2023}></YearTitle>
      <div>
        <a
          href={
            process.env.PUBLIC_URL +
            "/pdf/reports/supervisory/Sprava_DR_Keckovce_2022.pdf"
          }
          target="_blank"
          rel="noreferrer"
        >
          <FaRegFilePdf /> Správa dozornej rady za rok 2022
        </a>
      </div>
      <br />
    </div>
  );
}

export default SupervisoryBoard;
