import React from "react";
import "./App.css";
import Header from "./header";
import Footer from "./footer";
import Home from "./home";
import Navbar from "./navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Info from "./info";
import Authorities from "./authorities";
import Documents from "./documents";
import Legislation from "./legislation";
import Galery from "./galery";
import Contacts from "./contacts";
import Contract from "./documents/contract";
import Resolutions from "./documents/resolutions";
import Reports from "./documents/reports";
import Shutters from "./documents/shutters";
import Activities from "./documents/reports/activities";
import ActivityProjects from "./documents/reports/activityProjects";
import SupervisoryBoard from "./documents/reports/supervisoryBoard";
import FinancialManagement from "./documents/reports/financialManagement";
import Others from "./documents/reports/others";
import News from "./news";

function App() {
  return (
    <BrowserRouter>
      <Header></Header>
      <div
        className="row body"
        style={{
          backgroundImage: `url('${process.env.PUBLIC_URL}/pozadie.jpg')`,
          backgroundSize: "cover",
        }}
      >
        <div className="row content">
          <div className="hidden-sm hidden-md col-lg-first col-lg-3">
            <Navbar id="left" click={() => {}}></Navbar>
          </div>
          <div className="col-sm-last col-sm-12 col-md-first col-md-8 col-lg-6">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/info" element={<Info />} />
              <Route path="/organy" element={<Authorities />} />
              <Route path="/dokumenty" element={<Documents />} />
              <Route path="/dokumenty/zmluva" element={<Contract />} />
              <Route path="/dokumenty/uznesenia" element={<Resolutions />} />
              <Route path="/dokumenty/zavierky" element={<Shutters />} />
              <Route path="/dokumenty/spravy" element={<Reports />} />
              <Route path="dokumenty/spravy/cinnost" element={<Activities />} />
              <Route
                path="dokumenty/spravy/projekty"
                element={<ActivityProjects />}
              />
              <Route
                path="dokumenty/spravy/dozorna-rada"
                element={<SupervisoryBoard />}
              />
              <Route
                path="dokumenty/spravy/financne-hospodarenie"
                element={<FinancialManagement />}
              />
              <Route path="dokumenty/spravy/ine" element={<Others />} />
              <Route path="/legislativa" element={<Legislation />} />
              <Route path="/fotogaleria" element={<Galery />} />
              <Route path="/kontakty" element={<Contacts />} />
            </Routes>
          </div>
          <div className="col-sm-first col-sm-12 col-md-last col-md-4 col-lg-last col-lg-3">
            <News />
          </div>
        </div>
        <div className="row">
          <Footer></Footer>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
