import React from "react";
import PageTitle from "../titles/page-title";

function Authorities() {
  return (
    <div className="content-container">
      <PageTitle title="Orgány spoločenstva"></PageTitle>
      <h3>
        Výbor <small>Funkčné obdobie: 5 rokov</small>
      </h3>
      <div className="row">
        <div className="col-sm-12 col-md-8 col-lg-6">
          <hr />
        </div>
      </div>
      <ol>
        <li>
          <div className="row">
            <div className="col-sm-6 col-md-4 col-lg-4">Jaroslav Orenič</div>
            <div className="col-sm-6 col-md-4 col-lg-2">predseda</div>
          </div>
        </li>
        <li>
          <div className="row">
            <div className="col-sm-6 col-md-4 col-lg-4">Jozef Mikita</div>
            <div className="col-sm-6 col-md-4 col-lg-2">člen</div>
          </div>
        </li>
        <li>
          <div className="row">
            <div className="col-sm-6 col-md-4 col-lg-4">Ľuboš Riško</div>
            <div className="col-sm-6 col-md-4 col-lg-2">člen</div>
          </div>
        </li>
      </ol>
      <br />
      <br />
      <h3>
        Dozorná rada <small>Funkčné obdobie: 5 rokov</small>
      </h3>
      <div className="row">
        <div className="col-sm-12 col-md-8 col-lg-6">
          <hr />
        </div>
      </div>
      <ol>
        <li>
          <div className="row">
            <div className="col-sm-6 col-md-4 col-lg-4">
              Mgr. Slavomíra Čabrejová
            </div>
            <div className="col-sm-6 col-md-4 col-lg-2">predseda</div>
          </div>
        </li>
        <li>
          <div className="row">
            <div className="col-sm-6 col-md-4 col-lg-4">Ján Feňo</div>
            <div className="col-sm-6 col-md-4 col-lg-2">člen</div>
          </div>
        </li>
        <li>
          <div className="row">
            <div className="col-sm-6 col-md-4 col-lg-4">Michal Pavelčák</div>
            <div className="col-sm-6 col-md-4 col-lg-2">člen</div>
          </div>
        </li>
      </ol>
      <ol start={4}>
        <li>
          <div className="row">
            <div className="col-sm-6 col-md-4 col-lg-4">Jozef Horkavý</div>
            <div className="col-sm-6 col-md-4 col-lg-2">náhradník</div>
          </div>
        </li>
      </ol>
    </div>
  );
}

export default Authorities;
