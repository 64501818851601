import React from "react";
import { NavLink } from "react-router-dom";

interface NavbarProps {
  id: string;
  click: Function
}

function Navbar({ id, click }: NavbarProps) {
  return (
    <nav>
      <NavLink to="/" onClick={click()}>Domov</NavLink>
      <NavLink to="info" onClick={click()}>Základné informácie</NavLink>
      <NavLink to="organy" onClick={click()}>Orgány spoločenstva</NavLink>
      <div className="collapse">
        <input
          type="checkbox"
          id={`collapse-documents-${id}`}
          aria-hidden="true"
        />
        <label htmlFor={`collapse-documents-${id}`} aria-hidden="true">
          {">"} Dokumenty
        </label>
        <div>
          <NavLink to="dokumenty/zmluva" onClick={click()}>Zmluva o PS</NavLink>
          <NavLink to="dokumenty/uznesenia" onClick={click()}>Uznesenia</NavLink>
          <div className="collapse">
            <input
              type="checkbox"
              id={`collapse-summaries-${id}`}
              aria-hidden="true"
            />
            <label htmlFor={`collapse-summaries-${id}`} aria-hidden="true">
              {">"} Správy
            </label>
            <div>
              <NavLink to="dokumenty/spravy/cinnost" onClick={click()}>O činnosti</NavLink>
              <NavLink to="dokumenty/spravy/projekty" onClick={click()}>Plány činností</NavLink>
              <NavLink to="dokumenty/spravy/dozorna-rada" onClick={click()}>Dozorná rada</NavLink>
              <NavLink to="dokumenty/spravy/financne-hospodarenie" onClick={click()}>
                Finančné hospodárenie
              </NavLink>
              <NavLink to="dokumenty/spravy/ine" onClick={click()}>Iné</NavLink>
            </div>
          </div>
          <NavLink to="dokumenty/zavierky" onClick={click()}>Schválené účtovné závierky</NavLink>
        </div>
      </div>
      <NavLink to="legislativa" onClick={click()}>Legislatíva</NavLink>
      <NavLink to="fotogaleria" onClick={click()}>Fotogaléria</NavLink>
      <NavLink to="kontakty" onClick={click()}>Kontakty</NavLink>
    </nav>
  );
}

export default Navbar;
