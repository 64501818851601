import React from "react";
import PageTitle from "../../../titles/page-title";
import { FaRegFilePdf } from "react-icons/fa";
import YearTitle from "../../../titles/year-title";

function ActivityProjects() {
  return (
    <div className="content-container">
      <PageTitle title="Plány činností"></PageTitle>
      <YearTitle year={2024}></YearTitle>
      <div>
        <a
          href={
            process.env.PUBLIC_URL +
            "/pdf/reports/projects/Projekt_cinnosti_na_rok_2024.pdf"
          }
          target="_blank"
          rel="noreferrer"
        >
          <FaRegFilePdf /> Plán činností na rok 2024
        </a>
      </div>
      <br />
      <YearTitle year={2023}></YearTitle>
      <div>
        <a
          href={
            process.env.PUBLIC_URL +
            "/pdf/reports/projects/Projekt_cinnosti_na_rok_2023.pdf"
          }
          target="_blank"
          rel="noreferrer"
        >
          <FaRegFilePdf /> Plán činností na rok 2023
        </a>
      </div>
      <br />
    </div>
  );
}

export default ActivityProjects;
