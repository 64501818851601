import React from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../navbar";

function Header() {
  function closeBurgerMenu(): void {
    const element = document.getElementById('close-burger-menu');
    element?.click();
  }

  return (
    <header className="header">
      <div className="row">
        <div className="col-sm-12 hidden-lg burger-menu">
          <label
            htmlFor="burger-menu"
            className="button drawer-toggle persistent"
          />
          <input
            type="checkbox"
            id="burger-menu"
            className="drawer persistent"
          />
          <div>
            <label htmlFor="burger-menu" id="close-burger-menu" className="drawer-close"/>
            <Navbar id="header" click={() => closeBurgerMenu}></Navbar>
          </div>
        </div>
        <div className="hidden-sm hidden-md col-lg-12">
          <NavLink to="/" className="button">
            Domov
          </NavLink>
          <NavLink to="/info" className="button">
            Základné informácie
          </NavLink>
          <NavLink to="/organy" className="button">
            Orgány spoločenstva
          </NavLink>
          <NavLink to="/dokumenty" className="button">
            Dokumenty
          </NavLink>
          <NavLink to="/legislativa" className="button">
            Legislatíva
          </NavLink>
          <NavLink to="/fotogaleria" className="button">
            Fotogaléria
          </NavLink>
          <NavLink to="/kontakty" className="button">
            Kontakty
          </NavLink>
        </div>
      </div>
    </header>
  );
}

export default Header;
