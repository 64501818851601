import React from "react";
import PageTitle from "../titles/page-title";
import { FaRegFilePdf } from "react-icons/fa";

function Info() {
  return (
    <div className="content-container">
      <PageTitle title="Základné informácie"></PageTitle>
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <strong>Názov:</strong>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-4">
          Združenie vlastníkov "p.s." Kečkovce
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <strong>Sídlo:</strong>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-4">
          Kečkovce 35/35 09011, Kečkovce
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <strong>IČO:</strong>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-4">42381649</div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <strong>DIČ:</strong>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-4">2024098560</div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <strong>Dátum zápisu:</strong>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-4">22.02.2014</div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <strong>Vložka:</strong>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-4">0004/712</div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <strong>Katastrálne územie:</strong>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-4">
          Kečkovce, Havranec, Vápeník
        </div>
      </div>

      <table>
        <caption>
          <h3>Sumár obhospodarovaného majetku</h3>
        </caption>
        <thead>
          <tr>
			      <th>Lesný celok</th>
			      <th>Výmera</th>
            <th>&nbsp;</th>
		      </tr>
        </thead>
        <tbody>
		      <tr>
			      <td>Lesy na LHC Krajná Poľana a Kapišová</td>
			      <td>Lesné pozemky</td>
			      <td>55,37 ha</td>
		      </tr>
		      <tr>
            <td>&nbsp;</td>
			      <td>Funkčné plochy</td>
			      <td>0,34 ha</td>
		      </tr>
		      <tr>
			      <td>Lesy na LHC Svidník</td>
			      <td>Lesné pozemky</td>
			      <td>332,86 ha</td>
		      </tr>
		      <tr>
            <td>&nbsp;</td>
			      <td>Funkčné plochy</td>
			      <td>4,01 ha</td>
		      </tr>
		      <tr>
			      <td><strong>Spolu</strong></td>
            <td>&nbsp;</td>
			      <td><strong>392,58 ha</strong></td>
		      </tr>
        </tbody>
      </table>
      <br />
      <br />

      <p>
        <a
          href={process.env.PUBLIC_URL + "/pdf/info/Porastova_mapa.pdf"}
          target="_blank"
          rel="noreferrer"
        >
          <FaRegFilePdf /> Porastová mapa
        </a>
      </p>
      <p>
        <a
          href={process.env.PUBLIC_URL + "/pdf/info/Zoznam_JPRL.pdf"}
          target="_blank"
          rel="noreferrer"
        >
          <FaRegFilePdf /> Zoznam jednotiek priestorového rozdelenia lesa (JPRL)
        </a>
      </p>
      <p>
        <a
          href={process.env.PUBLIC_URL + "/pdf/info/Zoznam_parciel.pdf"}
          target="_blank"
          rel="noreferrer"
        >
          <FaRegFilePdf /> Zoznam obhospodarovaných parciel a ich výmera
        </a>
      </p>
    </div>
  );
}

export default Info;
