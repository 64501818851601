import React from "react";
import PageTitle from "../../../titles/page-title";
import { FaRegFilePdf } from "react-icons/fa";

function Others() {
  return (
    <div className="content-container">
      <PageTitle title="Iné"></PageTitle>
      <div>
        <a
          href={process.env.PUBLIC_URL + "/pdf/others/SOP_Keckovce.pdf"}
          target="_blank"
          rel="noreferrer"
        >
          <FaRegFilePdf /> Výskyt hniezdnej lokality druhu vtáka v zmysle
          ustanovení § 39 ods. 1 písm. h) nariadenia vlády SR č. 3/2023 Z. z.
        </a>
      </div>
    </div>
  );
}

export default Others;
