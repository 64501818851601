import React from "react";
import PageTitle from "../titles/page-title";

function Legislation() {
  return (
    <div className="content-container">
      <PageTitle title="Legislatíva"></PageTitle>
      <div>
        <a
          href="https://www.slov-lex.sk/pravne-predpisy/SK/ZZ/2013/97/20221101"
          target="_blank"
          rel="noreferrer"
        >
          &#167; Zákon o pozemkových spoločenstvách
        </a>
      </div>
      <div>
        <a
          href="https://www.slov-lex.sk/pravne-predpisy/SK/ZZ/2005/326/20230101"
          target="_blank"
          rel="noreferrer"
        >
          &#167; Zákon o lesoch
        </a>
      </div>
    </div>
  );
}

export default Legislation;
