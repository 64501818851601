import React from "react";
import PageTitle from "../../titles/page-title";
import { FaRegFilePdf } from "react-icons/fa";
import YearTitle from "../../titles/year-title";

function Shutters() {
  return (
    <div className="content-container">
      <PageTitle title="Schválené účtovné závierky"></PageTitle>
      <YearTitle year={2023}></YearTitle>
      <div>
        <a
          href={
            process.env.PUBLIC_URL + "/pdf/shutters/Uctovna_zavierka_2022.pdf"
          }
          target="_blank"
          rel="noreferrer"
        >
          <FaRegFilePdf /> Účtovná závierka za rok 2022
        </a>
      </div>
    </div>
  );
}

export default Shutters;
