import React from "react";
import { FaRegFilePdf } from "react-icons/fa";
import PageTitle from "../../titles/page-title";

function Contract() {
  return (
    <div className="content-container">
      <PageTitle title="Zmluva o pozemkovom spoločenstve"></PageTitle>
      <div>
        <a
          href={
            process.env.PUBLIC_URL +
            "/pdf/contract/Zmluva_o_pozemkovom_spolocenstve.pdf"
          }
          target="_blank"
          rel="noreferrer"
        >
          <FaRegFilePdf /> Zmluva o pozemkovom spoločenstve
        </a>
      </div>
      <br />
    </div>
  );
}

export default Contract;
