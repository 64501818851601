import React from "react";
import { FaRegFilePdf } from "react-icons/fa";
import PageTitle from "../../../titles/page-title";
import YearTitle from "../../../titles/year-title";

function FinancialManagement() {
  return (
    <div className="content-container">
      <PageTitle title="Finančné hospodárenie"></PageTitle>
      <YearTitle year={2024}></YearTitle>
      <div>
        <a
          href={
            process.env.PUBLIC_URL +
            "/pdf/reports/finance/Sprava_o_financnom_hospodareni_2023.pdf"
          }
          target="_blank"
          rel="noreferrer"
        >
          <FaRegFilePdf /> Správa o finančnom hospodárení za rok 2023
        </a>
      </div>
      <br />
      <YearTitle year={2023}></YearTitle>
      <div>
        <a
          href={
            process.env.PUBLIC_URL +
            "/pdf/reports/finance/Sprava_o_financnom_hospodareni_2022.pdf"
          }
          target="_blank"
          rel="noreferrer"
        >
          <FaRegFilePdf /> Správa o finančnom hospodárení za rok 2022
        </a>
      </div>
      <br />
    </div>
  );
}

export default FinancialManagement;
