import React from "react";
import PageTitle from "../titles/page-title";
import ImageGallery from "react-image-gallery";

function Galery() {
  const images = [
    {
      original: process.env.PUBLIC_URL + "/images/image2.jpg",
      thumbnail: process.env.PUBLIC_URL + "/images/image2.jpg",
    },
    {
      original: process.env.PUBLIC_URL + "/images/image1.jpg",
      thumbnail: process.env.PUBLIC_URL + "/images/image1.jpg",
    },
  ];
  return (
    <div className="content-container">
      <PageTitle title="Fotogaléria"></PageTitle>
      <ImageGallery items={images} />
    </div>
  );
}

export default Galery;
