import React from "react";
import { FaRegFilePdf } from "react-icons/fa";
import PageTitle from "../../titles/page-title";
import YearTitle from "../../titles/year-title";

function Resolutions() {
  return (
    <div className="content-container">
      <PageTitle title="Uznesenia"></PageTitle>
      <YearTitle year={2023}></YearTitle>
      <div>
        <a
          href={
            process.env.PUBLIC_URL +
            "/pdf/resolutions/Uznesenie_z_VZ_Keckovce_29-4-2023.pdf"
          }
          target="_blank"
          rel="noreferrer"
        >
          <FaRegFilePdf /> Uznesenie z valného zhromaždenia 29.4.2023
        </a>
      </div>
      <br />
    </div>
  );
}

export default Resolutions;
