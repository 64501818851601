import React from "react";
import PageTitle from "../titles/page-title";

function News() {
  return (
    <div className="content-container">
      <PageTitle title="Oznamy"></PageTitle>
      <p>Žiadne nové oznamy</p>
    </div>
  );
}

export default News;
