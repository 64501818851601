import React from "react";

interface YeartTitleProps {
  year: number;
}

function YearTitle({ year }: YeartTitleProps) {
  return (
    <div className="row year-title">
      <div className="col-sm-8 col-md-4 lg-2">
        <h3>{year}</h3>
        <hr />
      </div>
    </div>
  );
}

export default YearTitle;
